//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// const brand = 'ZBXN';
import male from '@/assets/customer/male.png';
import female from '@/assets/customer/female.png';
import filter from '@/assets/customer/filter.png'
export default {
    name: "Index",
    data() {
        return {
            mobile: '',
            memberInfo: {},
            memberList: [],
            filterPop: false,
            acitvities: [
                {label: '小于30天', value: '0,30', selected: false},
                {label: '90~180天', value: '90,180', selected: false},
                {label: '181~365天', value: '181,365', selected: false},
                {label: '大于365天', value: '365,21900', selected: false},
            ],
            levels: [
                {label: '凤凰会员', value: 'ZB24', selected: false},
                {label: '金鸟会员', value: 'ZB23', selected: false},
                {label: '银鸟会员', value: 'ZB22', selected: false},
                {label: '普通会员', value: 'ZB21', selected: false},
                // {label: 'V1', value: 'ZB00', selected: false},
                // {label: 'V2', value: 'ZB01', selected: false},
                // {label: 'V3', value: 'ZB02', selected: false},
                // {label: 'V4', value: 'ZB03', selected: false},
            ],
            others: [
                {label: '本月生日会员', value: 'current', selected: false},
                {label: '次月生日会员', value: 'next', selected: false},
                {label: '未消费会员', selected: false},
            ],
            loading: false,
            birthMonth: '',
            begCrDate: '',
            endCrtDate: '',
            level: '',
            original: [],
            male,
            female,
            filter
        }
    },
    mounted() {
        window.document.title = '我的会员'
    },
    activated() {
        window.document.title = '我的会员'
    },
    created() {
        window.document.title = '我的会员'
        this.getMemberList();

        let value = '0,30';
        let days = value.split(',');
        let begin = this.$moment().subtract( Number(days[1]), 'day').format('yyyyMMDD');

        console.log(begin);
    },
    methods: {
        searchMobile() {
            let searchMobile = this.mobile;
            let temp = [];
            const original = this.original;
            if (searchMobile && searchMobile.trim()!= ''){
                original.forEach(item=>{
                    if (item.mobile.indexOf(searchMobile)>-1){
                        temp.push(item);
                    }
                });

            }else {
                temp = JSON.parse(JSON.stringify(original));
            }
            this.memberList = temp;
        },
        getMemberList() {
            if (this.loading) return;
            this.loading = true;
            const that = this;
            this.memberList = [];
            this.axios.post('/' + this.$ajaxPrefix.consumer + '/v1/wx/cp/crm/store/getExclusiveCustomers', {
            // this.axios.post('/v1/wx/cp/crm/store/getExclusiveCustomers', {
                "pager": {
                    "pageIndex": 1,
                    "pageSize": 20,
                    "rowsCount": 0,
                    "start": 0
                },
                "queryMap": {
                    // "guideID": "",
                    // "brand": brand,
                    "birthMonth": this.birthMonth,
                    "begCrDate":this.begCrDate,
                    "endCrtDate": this.endCrtDate,
                    "level": this.level,
                    "brand": "ZBXN"
                    // "level": 'ZB24'
                }
            }).then(res => {
                if (res && res.data) {
                    that.original = res.data;
                    if (Array.isArray(that.original)){
                        that.original.forEach(item=>{
                            if (item.lastConsumeDate != ''){
                                item.lastConsumeDate = that.$moment(item.lastConsumeDate).format('yyyy-MM-DD');
                            }
                            if (item.mobile != ''){
                                item.mobileTxt = item.mobile.slice(0, 4) + '****' + item.mobile.slice(-3);
                            }else {
                                item.mobileTxt = '';
                            }
                        });
                    }else {
                        that.original = [];
                    }
                    // that.memberList = JSON.parse(JSON.stringify(that.original));
                    that.searchMobile();
                }else {
                    that.original = [];
                }
                this.loading = false;
            })
        },
        toDetail(bpid, name) {
            this.$router.push('/customer/list?bpid=' + bpid +'&name=' + name );
        },
        toggle(name, index) {
            if (name == 'acitvities'){
                // this.acitvities.forEach(item=>{
                //     item.selected = false;
                // });
                // this[name][index]['selected'] = true;
                if (this[name][index].selected){this[name][index].selected = !this[name][index].selected}else {
                    this.acitvities.forEach(item=>{
                        item.selected = false;
                    });
                    this[name][index]['selected'] = true;
                }
            }else {
                this[name][index]['selected'] = !this[name][index]['selected'];
            }

        },
        sureFilter() {
            const that = this;
            let act = this.acitvities; //消费时间
            let begin = '', end = '';
            act.forEach(item=>{
                if (item.selected){
                    let days = item.value.split(',');
                    end = this.$moment().subtract(Number(days[0]), 'days').format('yyyyMMDD');
                    begin = this.$moment().subtract(Number(days[1]), 'days').format('yyyyMMDD');
                    that.begCrDate = begin;
                    that.endCrtDate = end;
                }
            });

            let level = this.levels;
            let temp = [];
            level.forEach(item=>{
                if (item.selected){
                    temp.push(item.value);
                }
            });
            this.level = temp.join(',');

            let others = this.others;
            let monthTemp = [];
            others.forEach(item=>{
                if (item.value == 'current' && item.selected){
                    monthTemp.push(that.$moment().format('MM'));
                }
                if (item.value == 'next' && item.selected){
                    monthTemp.push(that.$moment().add(1, 'months').format('MM'));
                }
            });
            this.birthMonth = monthTemp.join(',');
            this.getMemberList();
            this.filterPop = false;
        }
    }
}
